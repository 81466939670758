<!-- 绑定账号 -->
<template>
  <div class="bindaccount">
  	<div class="top">
  		<div class="title">
  			<img :src="logos" @click='linklogo'>
  			<p>账号绑定</p>
  		</div>
  	</div>
    <div class="centerbg">
      <div class="bindbox" v-if='step==0'>
        <h6 class="h6">绑定微微定账号</h6>
        <div class="msginfo">
          <div class="img">
            <img :src="seninfo?seninfo.url:''">
          </div>
          <div class="info">
            <p class="p">{{seninfo?seninfo.name:''}}</p>
            <p class="p1">您的微信账号尚未绑定微微定账号，请登录或注册微微定账号完成绑定。</p>
          </div>
        </div>
        <div class="bindbtn">
          <button @click='loginbind'>登录微微定账号并绑定</button>
          <button @click='accountlink'>注册微微定账号并绑定</button>
          <button @click='closeBind'>取消</button>
        </div>
      </div>
      <div class="bindbox bindbox1" v-if='step==1'>
        <h6 class="h6">账号登录</h6>
        <div class="group-ipt">
          <input type="text" placeholder="手机号" v-model='user'>
          <span class="error" v-if='error.user'>{{error.user}}</span>
        </div>
        <div class="group-ipt">
          <input type="password" placeholder="密码" v-model='password'>
          <span class="error" v-if='error.password'>{{error.password}}</span>
        </div>
        <div class="group-btn">
          <button>忘记密码</button>
        </div>
        <div class="btnbox">
          <button @click='login'>登录</button>
        </div>
      </div>
      <div class="bindbox bindbox2" v-if='step==2'>
        <h6 class="h6">绑定成功</h6>
        <div class="bindimg">
          <div class="lt">
            <img :src="this.$store.state.user?this.$store.state.user.WxDisplayUrl:''">
            <p>{{this.$store.state.user?this.$store.state.user.ContactName:''}}</p>
          </div>
          <div class="ct">
            <svg><use xlink:href="#iconbangding"></use></svg>
          </div>
          <div class="rt">
            <img :src="this.$store.state.user?this.$store.state.user.WxDisplayUrl:''">
            <p>{{this.$store.state.user&&this._spf(this.$route.query.type)==1?this.$store.state.user.wxName:this.$store.state.user.QQName}}</p>
          </div>
        </div>
        <div class='bindinfo'>
          <p>您可使用微信账号快速登录微微定账号</p>
          <button @click='linkindex'>知道了</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {usermethod} from '../assets/js/common.js' //公用js
export default {
  name: 'bindaccount',
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
        name: "description",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoDescrition
      },
      {
        name: "Keywords",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoKey
      }
        ]
    }
  },
  props: {
    msg: String
  },
  components:{
  },
  data(){
  	return {
      htmltitle:'绑定账号-微微定',
      logos:null,//logo
      step:0,//绑定步骤
      user:'',//账号
      password:'',//密码
      error:{
        user:'',
        password:''
      },
      seninfo:null,
  	}
  },
  methods:{
    linkindex(){
      // 跳转首页
      this._router({then:()=>{
        this.$router.push({path: "/index.html"})
      }})
    },
    loginbind(){
      // 登录绑定
      this.step = 1
    },
    accountlink(){
      // 注册绑定
      this._router({then:()=>{
        this.$router.push({path: "/accountregister?id="+this._spf(this.$route.query.id)+'&type='+this._spf(this.$route.query.type)+'.html'})
      }})
    },
    closeBind(){
      // 取消绑定
      this._router({then:()=>{
        this.$router.push({path: "/index.html"})
      }})
    },
    linklogo(){
      // logo跳转
      if(this.$store.state.logoUrl){
        window.location.href=this.$store.state.logoUrl
      }
    },
    login(){
      var TEL_REGEXP = /^1[3456789]\d{9}$/;
      this.error.user=''
      this.error.password=''
      var _flag = true
      // 绑定登录
      if(!this.user){
        _flag = false
        this.error.user='请填写手机号'
      }
      if(!TEL_REGEXP.test(this.user)){
        _flag = false
        this.error.user='请填写正确的手机号'
      }
      if(!this.password){
        _flag = false
        this.error.password='请填写密码'
      }
      if(_flag){
        this._axios({then:()=>{
          this.axios.post(process.env.VUE_APP_URL+'Login/LoginBindOAuth?Phone='+this.user+'&PassWord='+this.password+'&Openid='+this._spf(this.$route.query.id)+'&type='+this._spf(this.$route.query.type),this.keys())
          .then(res => {
              if(res.data.Success){
                this.$store.dispatch("cmtSetUser",res.data.Data.CustomersDto);//赋值
                usermethod({method:'set',user:res.data.Data.CustomersDto});//存本地
                this._token({method:'set',token:res.data.Data.token})
                this.step = 2
              }else{
                this.$MessageBox.alert(res.data.Error,{type:'error'});
              }
            }, res => {
              // this.$MessageBox.alert('系统异常',{type:'error'});
              console.log('系统异常')
            })
        }})
      }
    },
    localReset(){
      if(JSON.parse(window.sessionStorage.getItem('seninfo'))){
        this.seninfo = JSON.parse(window.sessionStorage.getItem('seninfo'));
      }
      if(this.$store.state.user){
        // 绑定成功跳转
        this._router({then:()=>{
          this.$router.push({path: "/index.html"})
        }})
      }
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // 加载
      var _this =this;
      let b = function() {
        return new Promise(function(resolve, reject) {
            _this._axios({then:()=>{
              // logo
              _this.axios.post(process.env.VUE_APP_URLS+'Home/GetAdvertisingImg?type=2',_this.keys())
                .then(res => {
                  if(res.data.Success){
                    _this.logos = _this._src + res.data.Data.List[0].AdvertisingPath
                    _this.$store.dispatch("cmtLogoUrl",res.data.Data.List[0].LinkUrl);//改状态-logourl
                    resolve()
                  }else{
                    resolve()
                    _this.$MessageBox.alert(res.data.Error,{type:'error'});
                  }
                }, res => {
                  resolve()
                  // this.$MessageBox.alert('系统异常',{type:'error'});
                })
            }})
        })
      }
      this._reduce([b])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        this.localReset()
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  }
}
</script>
<style lang="scss">
	// body {
	// 	background: #fff;
	// }
</style>
<style  lang="scss" scoped>
.bindaccount {
  background: #fff;
  .top {
  	height: 134px;width: 1200px;margin:0 auto;
  	.title {
  		display: flex;align-items: center;height: 134px;
  		img {
  			display: block;cursor: pointer;margin-top:5px;
  		}
  		p   {
  			font-size: 24px;color:#fb429d;letter-spacing: 3px;position: relative;margin-left:40px;
  			&:after {
  				content: '';display: block;width: 1px;height: 48px;background: #fb429d;position: absolute;top:-6px;left:-16px;
  			}
  		}
  	}
  }
  .centerbg {
    background: #f2f2f2;padding-top:30px;
    .bindbox {
      width: 970px;height: 600px;background: #fff;margin:0 auto;margin-bottom:50px;
      .h6 {
        width: 920px;height: 60px;margin:0 auto;border-bottom:2px solid #f7f7f7;text-align: center;line-height: 60px;font-size: 20px;
      }
      .msginfo {
        .img {
          width: 100px;height: 100px;margin: 0 auto;margin-top:45px;
          img {
            display: block;width: 100px;height: 100px;border-radius: 50%;
          }
        }
        .info  {
          text-align: center;
          .p {
            font-size: 14px;color:#333333;margin:15px 0px;
          }
          .p1 {
            font-size: 14px;color:#333333;
          }
        }
      }
      .bindbtn {
        margin-top:65px;
        button {
          display: block;margin:0 auto;
          &:nth-child(1) {
            height: 44px;width: 250px;line-height: 44px;border-radius: 20px;background: #f54e6b;font-size: 14px;color:#fff;
          }
          &:nth-child(2) {
            height: 44px;width: 250px;line-height: 44px;border-radius: 20px;background: none;font-size: 14px;color:#f54e6b;border:1px solid #e5e5e5;margin-top:15px;
          }
          &:nth-child(3) {
            background: none;font-size: 14px;color:#b2b2b2;margin-top:18px;
          }
        }
      }
    }
    .bindbox1 {
      .h6 {
        margin-bottom:115px;
      }
      .group-ipt {
        position: relative;width: 330px;margin: 0 auto;margin-bottom:15px;
        input {
          border-bottom:1px solid #cccccc;width: 330px;height: 46px;
          &::placeholder {
            color:#ccc;font-size: 14px;
          }
        }
        .error {
          position: absolute;width: 330px;top:48px;left:0px;font-size: 12px;z-index: 99;color:#f54e6b;
        }
      }
      .group-btn {
        width: 330px;margin: 0 auto;zoom:1;margin-top:20px;
        button {
          background: none;font-size: 14px;color:#007dff;float:right;
        }
        &:after {
          content: '';display: block;clear:both;
        }
      }
      .btnbox {
        width: 330px;margin: 0 auto;
        button {
          width: 250px;height: 45px;text-align: center;line-height: 45px;display: block;margin:0 auto;margin-top:50px;color:#fff;background: #f54e6b;border-radius: 30px;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
    .bindbox2 {
      .bindimg {
        display: flex;justify-content: space-around;width: 500px;margin:0 auto;margin-top:80px;
        .lt {
          width: 150px;
          img {
            display: block;width: 110px;height: 110px; border-radius: 50%;margin:0 auto;
          }
          p {
            font-size:14px;text-align: center;color:#333;height: 25px;line-height: 25px;
          }
        }
        .ct {
          display: flex;justify-content: center;align-items: center;
          svg {
            width: 20px;height: 20px;fill: #7b7c80;
          }
        }
        .rt {
          width: 150px;
          img {
            display: block;width: 110px;height: 110px;border-radius: 50%;margin:0 auto;
          }
          p {
            font-size:14px;text-align: center;color:#333;height: 25px;line-height: 25px;
          }
        }
      }
      .bindinfo {
        p {
          font-size: 14px;color:#333;text-align: center;margin-top:15px;margin-bottom:80px;
        }
        button {
          display: block;margin:0 auto;width: 248px;height: 43px;border:1px solid #eaeaea;background: none;border-radius: 30px;color:#3397ff;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}
</style>
